import { Modal } from 'ant-design-vue'
import { queryScheduleOrderForClass } from '@/api/schedule'
import { getIsSystemId } from '@/utils/util'
import { IssuingTypeEnumText } from '@/enum/index'
import router from '@/router/index'


/**
 * 
 * @param {Object} params - The parameters object.
 * @param {string} params.formClassId - The ID of the form class.
 * @param {string[]} params.courseScheduleIds - An array of course schedule IDs.
 * @param {string} params.courseScheduleId - A single course schedule ID.
 * @returns {Promise<boolean>}
 */
export const autoCancelScheduleOrderModal = async (params) => {
    const { data } = await queryScheduleOrderForClass(params)


    if (!data) return true

    const { formClassId, ownerId, type, owner } = data
    const isSystemId = getIsSystemId(ownerId)




    const text = isSystemId
        ? "选中的课节当前正在发单找老师，若要继续操作，系统将自动取消排课单，同时创建“【中文】请假代课”任务，由相关辅导老师或教务进行跟进"
        : '选中的课节当前正在发单找老师，若要继续操作，系您将自动取消排课单';


    const jumpUrl = router.resolve({ path: '/workoutSchedule/issuing?formClassId=' + formClassId + '&status=PENDING_ORDER' })


    return new Promise(resolve => Modal.confirm({
        title: '排课单提示',
        content: (h) => {
            return h('div', [
                h('div', { style: 'font-weight: bold;text-align:left' }, text),
                h('p', { style: 'text-align:left; margin-top: 12px;' }, [
                    h('span', {}, '排课单: '),
                    h('a', { attrs: { href: jumpUrl.href, target: '_blank' } }, [IssuingTypeEnumText[type]]),
                ]),
                h('p', { style: 'text-align:left' }, [
                    h('span', {}, '创建人: '),
                    h('span', {}, owner),
                ])
            ])

        },
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
    }))
}
